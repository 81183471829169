import React from 'react';
import './CoachingContainer.css';

const TeamSection = ({ logoSrc, coachName, coachGames, coachDesc, coachRank, onClick }) => {
  return (
    <div className="grid-subcontainer-coach">
      <div className="grid-item-coach logo-section-coach">
        <img src={logoSrc} alt={`Logo ${coachName}`} className="jersey-image" />
      </div>
      <div className="grid-item-coach">
        <div className='header-section-coach'>
          <h3><br />{coachName}</h3>
          <button className='contact-coach-button' onClick={onClick}>View Coaching</button>
        </div>
        <div className="info-section-coach">
          <p>{coachDesc}</p>
          <p>Games: {coachGames}</p>
          <p>Current Rank: {coachRank}</p>
        </div>
      </div>
    </div>
  );
};

export default TeamSection;