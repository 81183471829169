import React, { useState } from 'react';
import './TeamContainer.css';

const CoachSection = ({ logoSrc, teamgame, teamName, teamdesc, leaderNames, coachNames, playerNames, ersatzNames, tryoutNames, onCopy }) => {
  const [showPopup, setShowPopup] = useState(false);

  const handleCopy = () => {
    onCopy();
    setShowPopup(true);
    setTimeout(() => setShowPopup(false), 1000); 
  };

  return (
    <div id={teamgame} className="grid-subcontainer-teams">
      <div className="grid-item-teams logo-section-teams">
        <img src={logoSrc} alt={`Logo ${teamName}`} className="jersey-image" />
      </div>
      <div className="grid-item-teams">
        <div className='header-section-teams'>
          <h3><br />{teamgame} | {teamName}</h3>
          <button className='contact-leader-button' onClick={handleCopy}>
            {showPopup ? "Copied discord tag to clipboard" : "Leaders Discord"}
          </button>
        </div>
        <div className="info-section-teams">
          <p>{teamdesc}</p>
          <div className="player-section-teams">
            <h3>Leader</h3>
            <h3>Coach</h3>
            <h3>Spieler</h3>
            <h3>Ersatz</h3>
            <h3>Tryout</h3>
            <div className="player-item" id="leader-main">
              {leaderNames.map((name, index) => <div id={name} key={index}>{name}</div>)}
            </div>
            <div className="player-item" id="coach-main">
              {coachNames.map((name, index) => <div id={name} key={index}>{name}</div>)}
            </div>
            <div className="player-item" id="spieler-main">
              {playerNames.map((name, index) => <div id={name} key={index}>{name}</div>)}
            </div>
            <div className="player-item" id="ersatz-main">
              {ersatzNames.map((name, index) => <div id={name} key={index}>{name}</div>)}
            </div>
            <div className="player-item" id="tryout-main">
              {tryoutNames.map((name, index) => <div id={name} key={index}>{name}</div>)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachSection;