import React, { useState } from 'react';
import './FAQSection.css';

const FAQSection = ({ faqs }) => {
  const [openFAQ, setOpenFAQ] = useState(0);

  const toggleFAQ = (index) => {
    setOpenFAQ(index);
  };

  return (
    <div className="faq-section">
      {faqs.map((faq, index) => (
        <div className="faq-item" key={index}>
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            <span className="faq-icon">{openFAQ === index ? '-' : '+'}</span>
            <span className="faq-text">{faq.question}</span>
          </div>
          {openFAQ === index && (
            <div className="faq-answer">
              <p>{faq.answer}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FAQSection;