import './Merch.css';
import GridBackground from '../../components/grid-background/GridBackground';

function Merch() {
    return (
    <div className="page-container">
    <h1><br />Merchandise</h1>
    <div className="container">
      <div className="grid-container-merch">
        <div className="grid-item-merch info-section-merch" onClick={() => window.open("https://www.korazo.net/de/produkt/paradox-zip-hoodie/")}>
          <img src="/images/ZipperMockup-Paragon.webp" alt="Paragon Zip Hoodie" className="jersey-image" />
          <p>Paragon Zip Hoodie</p>
        </div>
        <div className="grid-item-merch info-section-merch" onClick={() => window.open("https://www.korazo.net/de/produkt/team-paragon-hoodie/")}>
          <img src="/images/HoodieFrontMockup-Paragon-Black.webp" alt="Paragon Hoodie" className="jersey-image" />
          <p>Paragon Hoodie</p>
        </div>
        <div className="grid-item-merch info-section-merch" onClick={() => window.open("https://www.korazo.net/de/produkt/team-paragon-jogger/")}>
          <img src="/images/JoggerFrontMockup-Paragon-Black.webp" alt="Paragon Jogger" className="jersey-image" />
          <p>Paragon Jogger</p>
        </div>
        <div className="grid-item-merch info-section-merch" onClick={() => window.open("https://www.korazo.net/de/produkt/team-paragon-jersey/")}>
          <img src="/images/JerseyMockupFront-Paragon.webp" alt="Paragon Jersey" className="jersey-image" />
          <p>Paragon Jersey</p>
        </div>
        <div className="grid-item-merch info-section-merch" onClick={() => window.open("https://www.korazo.net/de/produkt/team-paragon-tshirt/")}>
          <img src="/images/tShirtMockupFront-Paragon-Black.webp" alt="Paragon T-Shirt" className="jersey-image" />
          <p>Paragon T-Shirt</p>
        </div>
        <div className="grid-item-merch info-section-merch" onClick={() => window.open("https://www.korazo.net/de/produkt/team-paragon-snapback/")}>
          <img src="/images/Snapback-Paragon-Black.webp" alt="Paragon Snapback" className="jersey-image" />
          <p>Paragon Snapback</p>
        </div>
        <div className="grid-item-merch info-section-merch" onClick={() => window.open("https://www.korazo.net/de/produkt/team-paragon-mousepad/")}>
          <img src="/images/MousepadMockup-Paragon-lila.webp" alt="Paragon Mousepad" className="jersey-image" />
          <p>Paragon Mousepad</p>
        </div>
        <div className="grid-item-merch info-section-merch" onClick={() => window.open("https://www.korazo.net/de/produkt/team-paragon-flag/")}>
          <img src="/images/FlaggePrintfile-Paragon-lila-2048x1276.webp" alt="Paragon Flag" className="jersey-image" />
          <p>Paragon Flag</p>
        </div>
      </div>
    </div>
    </div>
  )};
  
export default Merch;