import React, { useState } from 'react';
import './Start.css';
import DiscordButton from '../../components/discord-button/DiscordButton';
import GridBackground from '../../components/grid-background/GridBackground';
import FAQSection from '../../components/faq-section/FAQSection';

const faqs = [
  {
    question: 'Was ist Team Paragon?',
    answer: 'Team Paragon ist eine eSport Organisation, welche viele verschiedene Leute zusammenbringt und ihnen Hilft besser zu werden und zu wachsen.'
  },
  {
    question: 'Wie kann ich Team Paragon beitreten?',
    answer: 'Du kannst Team Paragon beitreten, indem du entweder die Teamleader selbst kontaktierst oder auf unserem Discord Server ein Ticket erstellst.'
  },
  {
    question: 'In welchen Spielen ist Team Paragon aktiv?',
    answer: 'Team Paragon ist aktiv in Valorant, CS:GO, Rocket League und Rainbow Six Siege.'
  }
];

function Start() {
  return (
    <div className="page-container">
      <div className="container">
        <GridBackground />
        <div className="grid-container">
          <div className="grid-item info-section">
            <div className="tagline">Valorant, CSGO, Rocket League & R6</div>
            <h1 className="text-appear-animation">Purge <br />eSports</h1>
            <p>
              Bei Team Paragon helfen wir Spielern und Coaches, das perfekte Team zu finden. Egal, ob du ein erfahrener Spieler oder ein aufstrebender Coach bist, wir bieten dir die Plattform und Unterstützung, die du benötigst, um erfolgreich zu sein.
            </p>
            <div className="buttons">
              <DiscordButton />
              <button className='match-history'>Match History</button>
            </div>
          </div>

          <div className="grid-item trikot-section">
            <div className="blue-circle"></div>
            <img src="/images/trikot.png" alt="Team Jersey" className="jersey-image" />
          </div>
          {/* Empty Grid Setion */}
          <div  className="grid-item info-section"></div>

          <div className="grid-item-right info-section">
            <div className="tagline">Fragen & Antworten</div>
            <h1>FAQ</h1>
            <FAQSection faqs={faqs} />
          </div>
{/*
          <div className="grid-item info-section">
            <div className="tagline">Valorant, CSGO, Rocket League & R6</div>
            <h1>TEAM <br />PARAGON</h1>
            <p>
              Bei Team Paragon helfen wir Spielern und Coaches, das perfekte Team zu finden. Egal, ob du ein erfahrener Spieler oder ein aufstrebender Coach bist, wir bieten dir die Plattform und Unterstützung, die du benötigst, um erfolgreich zu sein.
            </p>
            <div className="buttons">
              <DiscordButton />
              <button className='match-history'>Match History</button>
            </div>
          </div>
          <div className="grid-item info-section">
            <div className="tagline">Valorant, CSGO, Rocket League & R6</div>
            <h1>TEAM <br />PARAGON</h1>
            <p>
              Bei Team Paragon helfen wir Spielern und Coaches, das perfekte Team zu finden. Egal, ob du ein erfahrener Spieler oder ein aufstrebender Coach bist, wir bieten dir die Plattform und Unterstützung, die du benötigst, um erfolgreich zu sein.
            </p>
            <div className="buttons">
              <DiscordButton />
              <button className='match-history'>Match History</button>
            </div>
          </div>
*/}
        </div>
      </div>
    </div>
  );
}

export default Start;
