import './Tournaments.css';

function Tournaments() {
    return (
        <div>
          <h1 style={{textAlign: 'center'}}><br />Unsere Turniere</h1>
          <p>Dies ist erst der Anfang, aktuell kümmern wir uns um eine Riot Lizenz um Turniere mit Preisgeldern zu Organisieren.</p>
          <p>Hier wird mehr stehen wenn die Zeit dafür Reif ist!</p>
        </div>
    );
  };
  
export default Tournaments;