import React, { useState } from 'react';
import TeamSection from '../../components/team-container/TeamContainer.js';
import './Teams.css';

function Teams() {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedGame, setSelectedGame] = useState('All'); // State to track the selected game

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 1000);
  };

  const teams = [
    {
      logoSrc: "/logo192.png",
      teamgame: "Valorant",
      teamName: "Das Main Team (Immo)",
      teamdesc: "Unser Valorant Main Team, welches Purge eSports Repräsentiert, dies enthält die besten Spieler aus unserer Organisation.",
      leaderNames: ["Lowe"],
      coachNames: [""],
      playerNames: ["Grit", "McQueen", "JayKay", "Zeldris"],
      ersatzNames: ["PRG Jannixx"],
      tryoutNames: [""],
      onCopy: () => handleCopy("g2letshe")
    },
    {
      logoSrc: "/logo192.png",
      teamgame: "Valorant",
      teamName: "Das Elite Team (Immo)",
      teamdesc: "",
      leaderNames: ["PRG Killsteal"],
      coachNames: ["Name2"],
      playerNames: ["SNGY D1no"],
      ersatzNames: ["ヤギのテン"],
      tryoutNames: ["Name3", "Name5"],
      onCopy: () => handleCopy("killstealgmbh")
    },
    {
      logoSrc: "/logo192.png",
      teamgame: "Valorant",
      teamName: "Das Navy Team (Asc-Immo)",
      teamdesc: "",
      leaderNames: ["PRG Khang"],
      coachNames: [""],
      playerNames: ["3112", "9s DevVx3", "Faxx", "Konsti"],
      ersatzNames: ["KaeseToast", "interpsure"],
      tryoutNames: [""],
      onCopy: () => handleCopy("khang3005")
    },
    {
      logoSrc: "/logo192.png",
      teamgame: "Valorant",
      teamName: "Das Void Team (Asc-Immo)",
      teamdesc: "",
      leaderNames: ["zzz"],
      coachNames: [""],
      playerNames: ["CxrryMaxi", "EyL is My Love", "Drinks", "B3nno0"],
      ersatzNames: [""],
      tryoutNames: [""],
      onCopy: () => handleCopy("zzzvlrt")
    },
    {
      logoSrc: "/logo192.png",
      teamgame: "Valorant",
      teamName: "Das Academy Team (Dia-Asc)",
      teamdesc: "",
      leaderNames: [""],
      coachNames: [""],
      playerNames: ["", "", "", ""],
      ersatzNames: [""],
      tryoutNames: [""],
      onCopy: () => handleCopy("")
    },
    {
      logoSrc: "/logo192.png",
      teamgame: "Valorant",
      teamName: "Das Onyx Team (Dia-Asc)",
      teamdesc: "",
      leaderNames: [""],
      coachNames: [""],
      playerNames: ["", "", "", ""],
      ersatzNames: [""],
      tryoutNames: [""],
      onCopy: () => handleCopy("")
    },
    {
      logoSrc: "/logo192.png",
      teamgame: "Valorant",
      teamName: "Das Titan Team (Plat-Dia)",
      teamdesc: "",
      leaderNames: ["Newton"],
      coachNames: [""],
      playerNames: ["Drexy"],
      ersatzNames: [""],
      tryoutNames: ["Jason", "Newton"],
      onCopy: () => handleCopy("new.ton")
    },
    {
      logoSrc: "/logo192.png",
      teamgame: "Valorant",
      teamName: "Das Blossom Team (Plat-Dia)",
      teamdesc: "Unser Valorant Female Team.",
      leaderNames: [""],
      coachNames: [""],
      playerNames: ["", "", "", ""],
      ersatzNames: [""],
      tryoutNames: [""],
      onCopy: () => handleCopy("")
    },
    {
      logoSrc: "/logo192.png",
      teamgame: "Valorant",
      teamName: "Das Nova Team (Gold-Plat)",
      teamdesc: "",
      leaderNames: [""],
      coachNames: [""],
      playerNames: ["", "", "", ""],
      ersatzNames: [""],
      tryoutNames: [""],
      onCopy: () => handleCopy("")
    },
    {
      logoSrc: "/logo192.png",
      teamgame: "R6",
      teamName: "Das Main Team",
      teamdesc: "Unser Rainbow Six Siege Main Team, welches Purge eSports Repräsentiert, dies enthält die besten Spieler aus unserer Organisation.",
      leaderNames: [""],
      coachNames: [""],
      playerNames: ["", "", "", ""],
      ersatzNames: [""],
      tryoutNames: [""],
      onCopy: () => handleCopy("")
    },
//    {
//      logoSrc: "/logo192.png",
//      teamgame: "R6",
//      teamName: "Das Main Team",
//      teamdesc: "ELITE ipsum do diam voluptua. Atores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
//      leaderNames: ["Name1"],
//      coachNames: ["Name2"],
//      playerNames: ["Name1"],
//      ersatzNames: ["Name1"],
//      tryoutNames: ["Name3", "Name5"],
//      onCopy: () => handleCopy("killstealgmbh")
//    },
  ];

  const filteredTeams = selectedGame === 'All' ? teams : teams.filter(team => team.teamgame === selectedGame);

  return (
    <div className="page-container">
      <h1><br />Unsere Teams</h1>
      <div className="button-container">
        <button onClick={() => setSelectedGame('All')}>All</button>
        <button onClick={() => setSelectedGame('Valorant')}>Valorant</button>
        <button onClick={() => setSelectedGame('CSGO')}>CSGO</button>
        <button onClick={() => setSelectedGame('Rocket League')}>Rocket League</button>
        <button onClick={() => setSelectedGame('R6')}>Rainbow 6 Siege</button>
      </div>
      <div className="container">
        <div className="grid-container-teams">
          {filteredTeams.map((team, index) => (
            <React.Fragment key={index}>
              {/* <h1><br />{team.teamName}</h1> */}
              <TeamSection
                logoSrc={team.logoSrc}
                teamgame={team.teamgame}
                teamName={team.teamName}
                teamdesc={team.teamdesc}
                leaderNames={team.leaderNames}
                coachNames={team.coachNames}
                playerNames={team.playerNames}
                ersatzNames={team.ersatzNames}
                tryoutNames={team.tryoutNames}
                onCopy={team.onCopy}
                showPopup={showPopup}
              />
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Teams;