// src/components/navbar/Navbar.js
import React, { useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import './Navbar.css';
import DiscordButton from '../discord-button/DiscordButton';

function Navbar() {
  const { t, i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    if (lang) i18n.changeLanguage(lang);
  }, [lang, i18n]);

  return (
    <nav className="navbar">
      <Link to={`/${lang}/start`} className="navbar-logo">
        <img src="/images/logo.png" alt="Logo" />
        Purge eSports
      </Link>
      <div className="navbar-center">
        <ul className="navbar-links">
          <li><Link to={`/${lang}/start`}>{t('pages.start')}</Link></li>
          <li><Link to={`/${lang}/coaching`}>{t('pages.coaching')}</Link></li>
          <li><Link to={`/${lang}/teams`}>{t('pages.teams')}</Link></li>
          <li><Link to={`/${lang}/tournaments`}>{t('pages.tournaments')}</Link></li>
          <li><Link to={`/${lang}/partner`}>{t('pages.partner')}</Link></li>
          <li><Link to={`/${lang}/merch`}>{t('pages.merch')}</Link></li>
        </ul>
      </div>
      <div className="navbar-discord">
          <DiscordButton />
      </div>
    </nav>
  );
}

export default Navbar;
