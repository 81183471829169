// src/App.js
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import React from 'react';
import Navbar from "./components/navbar/Navbar";
import Coaching from './pages/coaching/Coaching';
import Merch from './pages/merch/Merch';
import NotFound from './pages/not-found/NotFound';
import Partner from './pages/partner/Partner';
import Start from './pages/start/Start';
import Teams from './pages/teams/Teams';
import Tournaments from './pages/tournaments/Tournaments';

// Styles and assets
import './App.css';
import './i18n';

const RedirectToDefaultLang = () => {
  let location = useLocation();
  const pathParts = location.pathname.split('/');
  if (pathParts.length < 2 || pathParts[1] === "") {
    return <Navigate to="/de/start" replace />;
  }
  return null;
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<RedirectToDefaultLang />} />
        <Route path="/:lang/*" element={<Layout />}>
          <Route path="start" element={<Start />} />
          <Route path="coaching" element={<Coaching />} />
          <Route path="teams" element={<Teams />} />
          <Route path="tournaments" element={<Tournaments />} />
          <Route path="partner" element={<Partner />} />
          <Route path="merch" element={<Merch />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Router>
  );
};

const Layout = () => {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="start" element={<Start />} />
        <Route path="coaching" element={<Coaching />} />
        <Route path="teams" element={<Teams />} />
        <Route path="tournaments" element={<Tournaments />} />
        <Route path="partner" element={<Partner />} />
        <Route path="merch" element={<Merch />} />
      </Routes>
    </div>
  );
};

export default App;
