import React, { useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const GridBackground = () => {
    const { t, i18n } = useTranslation();
    const { lang } = useParams();

    useEffect(() => {
        if (lang) i18n.changeLanguage(lang);
      }, [lang, i18n]);
    
      const canvasRef = useRef(null);
    
      useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
    
        const drawGrid = () => {
          canvas.width = 800; // feste Breite des Canvas
          canvas.height = 800; // feste Höhe des Canvas
    
          const gridSpacing = 100; // Abstand zwischen den Linien
          const lineColor = 'rgba(255, 255, 255, 0.1)'; // Farbe und Transparenz der Linien
          const pointColor = 'rgba(255, 255, 255, 0.3)'; // Farbe und Transparenz der Punkte
    
          context.clearRect(0, 0, canvas.width, canvas.height);
    
          // Vertikale Linien
          for (let x = 0; x <= canvas.width; x += gridSpacing) {
            context.beginPath();
            context.moveTo(x, 0);
            context.lineTo(x, canvas.height);
            context.strokeStyle = lineColor;
            context.stroke();
          }
    
          // Horizontale Linien
          for (let y = 0; y <= canvas.height; y += gridSpacing) {
            context.beginPath();
            context.moveTo(0, y);
            context.lineTo(canvas.width, y);
            context.strokeStyle = lineColor;
            context.stroke();
          }
    
          // Punkte an den Schnittstellen
          for (let x = 0; x <= canvas.width; x += gridSpacing) {
            for (let y = 0; y <= canvas.height; y += gridSpacing) {
              context.beginPath();
              context.arc(x, y, 2, 0, Math.PI * 2);
              context.fillStyle = pointColor;
              context.fill();
            }
          }
        };
    
        drawGrid();
      }, []);

    return (
        <div className="grid-background">
            <canvas ref={canvasRef} className="gridCanvas"></canvas>
        </div>
    );
};

export default GridBackground;